/**
 * v0 by Vercel.
 * ../see https://v0.dev/t/aooa43EE3KY
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */
'use client';

import { useCallback, useEffect, useState } from 'react';
import CreateAccount from './app/components/CreateAccount';
import LoginForm from './app/components/LoginForm';
import Redeemed from './app/components/Redeemed';
import RedeemForm from './app/components/RedeemForm';
import { LoginStates } from './lib/types/LoginStates';
import UserSession from './lib/types/UserSession';

export default function App() {
  const [session_state, setSessionState] = useState<LoginStates>(
    LoginStates.LOGGED_OUT,
  );

  const [redeemed_preview, setRedeemedPreview] =
    //@ts-ignore
    useState<RedeemResponse | null>(null);

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<string>('');

  const [user, setUser] = useState<UserSession | null>(null);

  const Display = useCallback(() => {
    switch (session_state) {
      case LoginStates.LOGGED_IN:
        return (
          <RedeemForm
            handleSetRedeemed={setRedeemedPreview}
            user={user}
            nav_to={setSessionState}
          />
        );
      case LoginStates.CONFIRMED_REDEMPTION:
        return (
          <Redeemed
            handleSetRedeemed={setRedeemedPreview}
            redeemed={redeemed_preview}
            nav_to={setSessionState}
          />
        );
      case LoginStates.CREATING_ACCOUNT:
        return (
          <CreateAccount
            handleSetUser={(u: UserSession) => setUser(u)}
            nav_to={setSessionState}
          />
        );
      default:
        return (
          <LoginForm
            handleSetUser={(u: UserSession) => setUser(u)}
            nav_to={setSessionState}
          />
        );
    }
  }, [user?.auth, setUser, session_state]);

  return (
    <div className="flex justify-center items-start h-screen bg-black">
      <Display />
    </div>
  );
}
