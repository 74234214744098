// cookieUtils.ts

const COOKIE_NAME = 'mdc_s';

const default_hours = (() => {
  if (process.env.NODE_ENV === 'development') {
    return 0.5;
  }
  return 9;
})();

/**
 * Sets a session cookie with a hardcoded name.
 * @param value - The value to set in the cookie.
 * @param days - The number of days until the cookie expires. Defaults to 7 days.
 */
export const setSessionCookie = (
  value: string,
  days: number = 0,
  hours: number = default_hours,
): void => {
  const cookieName = COOKIE_NAME;
  const date = new Date();
  date.setTime(date.getTime() + (days * 24 + hours) * 60 * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${cookieName}=${value};${expires};path=/`;
};

/**
 * Reads the value of the hardcoded session cookie.
 * @returns The value of the session cookie or null if not found.
 */
export const getSessionCookie = (): string | null => {
  const cookieName = COOKIE_NAME;
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookies = decodedCookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(cookieName) === 0) {
      return cookie.substring(cookieName.length, cookie.length);
    }
  }
  return null;
};

export const removeSessionCookie = (): void => {
  const cookieName = COOKIE_NAME;
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};
