import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { LoginStates } from '../../lib/types/LoginStates';

export default function GoToCreate(props: {
  nav_to?: Dispatch<SetStateAction<LoginStates>>;
}) {
  const handleGoToCreate = () => {
    if (props.nav_to) {
      props.nav_to(LoginStates.CREATING_ACCOUNT);
    }
  };
  return (
    <div
      style={{
        marginTop: 20,
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: 15,
      }}
    >
      <h1>Don&apos;t have an account?</h1>
      <span
        onClick={() => handleGoToCreate()}
        style={{
          cursor: 'pointer',
          color: 'green',
          textDecoration: 'none',
          fontSize: 'bold',
        }}
      >
        Create Account
      </span>
    </div>
  );
}
