export default function URLMaker() {
  const currentDomain = window.location.hostname;

  if (currentDomain.indexOf('localhost') > -1) {
  }

  if (currentDomain.indexOf('mcfarlanetoysdigital') > -1) {
    return 'https://mcfarlanedigital.app';
  }
  if (currentDomain.indexOf('mcfarlanetoys.digital') > -1) {
    return 'https://mcfarlanedigital.app';
  }
  return 'http://localhost:3000';
}
