import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Container } from './Container';
import { LoginStates } from '../../lib/types/LoginStates';
import RedeemResponse from '../../lib/types/RedeemResponse';
import UserSession from '../../lib/types/UserSession';
import URLMaker from '../../lib/url';

export default function RedeemForm(props: {
  handleSetRedeemed: Dispatch<SetStateAction<RedeemResponse>>;
  user: UserSession | null;
  nav_to?: Dispatch<SetStateAction<LoginStates>>;
}) {
  const [message, setMessage] = useState<string>('');

  const [wallet_address, setWalletAddress] = useState<string | undefined>();

  const [email, setEmail] = useState<string | null>(null);

  const [loading, setLoading] = useState(false);

  const loginDomain = URLMaker();

  useMemo(() => {
    if (!props.user) {
      return;
    }
    if (props.user.auth.preferredWallet) {
      setWalletAddress(props.user.auth.preferredWallet);
    } else if (props.user.wallets.length > 0) {
      setWalletAddress(props.user.wallets[0]);
    }
    if (props.user.auth.userEmail) {
      setEmail(props.user.auth.userEmail);
    }
  }, [props.user?.auth]);

  const handleLogin = (e: { preventDefault: () => void }) => {
    const run = async () => {
      try {
        // grab the form information
        const productCode = (
          document.getElementById('redemption_code') as HTMLInputElement
        ).value;

        /**
         * Schema:
         * productCode: the product code
         * type: email or wallet
         * address: wallet address or email address
         */

        interface RedeemRequest {
          productCode: string;
          type?: 'email' | 'wallet';
          address?: string;
        }

        let redeem: RedeemRequest = {
          productCode,
        };

        if (!props.user) {
          setMessage('User not set');
          return;
        }

        const { auth, wallets } = props.user;

        if (auth.preferredWallet) {
          redeem = {
            ...redeem,
            type: 'wallet',
            address: auth.preferredWallet,
          };
        } else if (wallets.length > 0) {
          redeem = {
            ...redeem,
            type: 'wallet',
            address: wallets[0],
          };
        } else {
          redeem = {
            ...redeem,
            type: 'email',
            address: auth.userEmail,
          };
        }

        // run a series of rule checks that verifies all of the properties are set for the redeem request
        if (!redeem.productCode) {
          setMessage('Product Code is required');
          return;
        }

        if (!['email', 'wallet'].includes(redeem.type ?? '')) {
          setMessage('Invalid body - type not specified');
          return;
        }

        if (!redeem.address) {
          setMessage('Invalid body - address not set');
          return;
        }

        const body = JSON.stringify(redeem);

        const response = await fetch(`${loginDomain}/products/unity/redeem`, {
          body,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${props.user.auth.authToken}`,
          },
          method: 'POST',
          mode: 'cors',
        });

        if (!response.ok) {
          const t = await response.text();

          // @ts-ignore
          setMessage(t);
          return;
        }

        const data = (await response.json()) as RedeemResponse;

        if (props.nav_to) {
          props.nav_to(LoginStates.CONFIRMED_REDEMPTION);
        }
        props.handleSetRedeemed(data);
      } catch (err) {
        console.error(err);
        // @ts-ignore
        setMessage(err ?? 'An error occurred');
      } finally {
        setLoading(false);
      }
    };
    e.preventDefault();
    setLoading(true);
    run();
  };

  const ErrorMessageDiv = useCallback(
    () => <p className="text-red-500 text-center">{message}</p>,
    [message],
  );

  return (
    <Container>
      <h1 className="text-3xl font-oswald font-bold mb-2 text-center">
        Redeem a Code
      </h1>
      <div>
        <ErrorMessageDiv />
      </div>
      <form className="space-y-4" onSubmit={handleLogin}>
        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Redemption Code
          </label>
          <input
            id="redemption_code"
            placeholder="Enter the redemption code on your code card"
            className="w-full px-3 py-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500"
          />
        </div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Your item will be redeemed to the following address:{' '}
          {wallet_address ?? email}
        </label>
        <div className="mt-8">
          <button
            type="submit"
            className="w-full bg-green-500 hover:bg-green-600 text-white font-oswald font-bold py-2 px-4 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 flex items-center justify-center"
          >
            {loading ? (
              <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white" />
            ) : (
              'Redeem'
            )}
          </button>
        </div>
      </form>
      <br />
      <label className="block text-sm font-medium text-gray-700 mb-1 italic">
        If you are receiving to a wallet, this is because you specified a
        Preferred Wallet in your account settings. If you are receiving to an
        email, this is because you do not have a Preferred Wallet set.
      </label>
    </Container>
  );
}
