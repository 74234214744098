import { setSessionCookie } from '../session';
import UserSession from '../types/UserSession';
import URLMaker from '../url';
import loginUser from './login';

const loginDomain = 'http://localhost:3000';

/**
 * Handles loging in the user and receiving a session token
 *
 * This also sets the token to the session cookie
 *
 * @param username
 * @param password
 * @returns
 */
async function createUser(
  username: string,
  email: string,
  password: string,
): Promise<void> {
  const body = JSON.stringify({
    username,
    password,
    email,
  });

  const loginDomain = URLMaker();

  const response = await fetch(`${loginDomain}/users/signup`, {
    body,
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    mode: 'cors',
  });

  if (!response.ok) {
    const t = await response.text();
    throw new Error(t);
  }
}

export default createUser;
