// import * as Types from '@/lib/types';
// import  from '@/lib/types/UserSession';
// import { getSessionCookie } from '@/lib/session';
// import { LoginStates } from '@/lib/types/LoginStates';
// import UserSession from '@/lib/types/UserSession';
// import { Dispatch, SetStateAction, useCallback, useState } from 'react';
// import ToLogin from './ToLogin';
// import createUser from '@/lib/mdc/create';
// import { Container } from './Container';

import { Container } from './Container'
import { Dispatch, SetStateAction, useState, useCallback } from "react";
import createUser from "../../lib/mdc/create";
import { getSessionCookie } from "../../lib/session";
import { LoginStates } from "../../lib/types/LoginStates";
import UserSession from "../../lib/types/UserSession";
import ToLogin from "./ToLogin";

export default function CreateAccount(props: {
  handleSetUser: (u: UserSession) => void;
  nav_to?: Dispatch<SetStateAction<LoginStates>>;
}) {
  const [message, setMessage] = useState<string>('');

  const [loading, setLoading] = useState(false);

  const [created, setCreated] = useState(false);

  const [accepted_tos, setAcceptedTos] = useState(false);

  const [accepted_privacy, setAcceptedPrivacy] = useState(false);

  const [ack_not_minor, settAckNotMinor] = useState(false);

  const handleNavToLoginIfSessionExists = (user?: UserSession) => {
    if (getSessionCookie()) {
      if (user) {
        props.handleSetUser(user);
      }
      props.nav_to?.(LoginStates.LOGGED_IN);
      return;
    }

    throw new Error('No session token received');
  };

  const handleCreateUser = async () => {
    // grab the form information
    const username = (document.getElementById('username') as HTMLInputElement)
      .value;
    const email = (document.getElementById('email') as HTMLInputElement).value;
    const password = (document.getElementById('password') as HTMLInputElement)
      .value;
    const password_confirmation = (
      document.getElementById('password') as HTMLInputElement
    ).value;

    if (!username || !email || !password || !password_confirmation) {
      setMessage('All fields are required');
      return;
    }

    if (password !== password_confirmation) {
      setMessage('Passwords do not match');
      return;
    }

    await createUser(username, email, password);

    setTimeout(async () => {
      if (props.nav_to) {
        props.nav_to(LoginStates.LOGGED_OUT);
      }
    }, 4000);

    setCreated(true);
  };

  const createUserHandler = (e: { preventDefault: () => void }) => {
    const run = async () => {
      try {
        await handleCreateUser();
      } catch (err) {
        console.error(err);

        //@ts-ignore
        if (err.message === 'No session token received') {
          setMessage('Invalid username or password');
        } else {
          // @ts-ignore
          setMessage(err?.message ?? 'An error occurred');
        }
      } finally {
        setLoading(false);
      }
    };
    e.preventDefault();
    setLoading(true);
    run();
  };

  const ErrorMessageDiv = useCallback(
    () => <p className="text-red-500 text-center">{message}</p>,
    [message],
  );

  //   useMemo(() => {
  //     try {
  //       handleNavToLoginIfSessionExists();
  //     } catch (e) {
  //       //@ts-ignore
  //       if (
  //         //@ts-ignore
  //         e.message !== 'No session token received' &&
  //         //@ts-ignore
  //         e.message !== 'document is not defined'
  //       ) {
  //         //@ts-ignore
  //         setMessage(e.message ?? 'An error occurred');
  //       }
  //     }
  //   }, []);

  if (created === true) {
    return (
      <div className="bg-white shadow-lg p-8 w-full max-w-md">
        <h1 className="text-2xl font-oswald font-bold mb-2 text-center">
          Your account was created!
        </h1>
        {/* <h1 className="text-center text-gray-600 mb-3">
        </h1> */}
        <div>
          <p className="text-grey-500 text-center">
            You will be automatically redirected to log in to your account.
          </p>
          <p className="text-grey-500 text-center">
            You will receive a confirmation email to confirm your address,
            please do so as soon as possible.
          </p>
        </div>
      </div>
    );
  }

  return (
    <Container>
      <h1 className="text-3xl font-oswald font-bold mb-2 text-center">
        Create Your Account
      </h1>
      <p className="text-center text-gray-600 mb-6">
        Please fill out the form bellow to create your account
      </p>
      <div>
        <ErrorMessageDiv />
      </div>
      <form className="space-y-4" onSubmit={createUserHandler}>
        <div>
          <label
            htmlFor="username"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Username
          </label>
          <input
            id="username"
            //   type="user"
            placeholder="Enter your username"
            className="w-full px-3 py-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500"
          />
        </div>
        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Email
          </label>
          <input
            id="email"
            //   type="user"
            placeholder="Enter your email"
            className="w-full px-3 py-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500"
          />
        </div>
        <div>
          <label
            htmlFor="password"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Password
          </label>
          <input
            id="password"
            type="password"
            placeholder="Enter your password"
            className="w-full px-3 py-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500"
          />
        </div>
        <div>
          <label
            htmlFor="password_confirmation"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Confirm password
          </label>
          <input
            id="password_confirmation"
            type="password"
            placeholder="Re-enter your password"
            className="w-full px-3 py-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500"
          />
        </div>
        <div className="inline-flex items-center">
          <label
            className="relative flex items-center p-3 rounded-full cursor-pointer"
            htmlFor="checkbox"
            style={{ paddingBottom: 2 }}
          >
            <input
              type="checkbox"
              className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
              id="ack_not_minor"
              checked={ack_not_minor}
              onChange={() => settAckNotMinor(!ack_not_minor)}
              style={{ marginRight: 10 }}
            />
            <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-3.5 w-3.5"
                viewBox="0 0 20 20"
                fill="currentColor"
                stroke="currentColor"
                stroke-width="1"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </span>
            I am 18 years old or older
          </label>
        </div>
        <div className="inline-flex items-center" style={{ marginTop: 0 }}>
          <label
            className="relative flex items-center p-3 rounded-full cursor-pointer"
            htmlFor="checkbox"
            style={{ paddingBottom: 2 }}
          >
            <input
              type="checkbox"
              className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
              id="accepted_tos"
              checked={accepted_tos}
              onChange={() => setAcceptedTos(!accepted_tos)}
              style={{ marginRight: 10 }}
            />
            <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-3.5 w-3.5"
                viewBox="0 0 20 20"
                fill="currentColor"
                stroke="currentColor"
                stroke-width="1"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </span>
            I agree to the Terms of Service
          </label>
        </div>
        <div className="inline-flex items-center" style={{ marginTop: 0 }}>
          <label
            className="relative flex items-center p-3 rounded-full cursor-pointer"
            htmlFor="accepted_tos"
            style={{ paddingBottom: 2 }}
          >
            <input
              type="checkbox"
              className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-900 checked:before:bg-gray-100 hover:before:opacity-10"
              id="accepted_tos"
              checked={accepted_privacy}
              onChange={() => setAcceptedPrivacy(!accepted_privacy)}
              style={{ marginRight: 10 }}
            />
            <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-3.5 w-3.5"
                viewBox="0 0 20 20"
                fill="white"
                stroke="white"
                stroke-width="1"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </span>

            <label
              className="mt-px font-light text-gray-700 cursor-pointer select-none"
              htmlFor="check"
            >
              I agree to the Privacy Policy
            </label>
          </label>
        </div>
        <div className="mt-8">
          <button
            type="submit"
            className="w-full bg-green-500 hover:bg-green-600 text-white font-oswald font-bold py-2 px-4 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 flex items-center justify-center"
            disabled={!accepted_tos || !accepted_privacy || !ack_not_minor}
            style={{
              opacity:
                !accepted_tos || !accepted_privacy || !ack_not_minor ? 0.5 : 1,
            }}
          >
            {loading ? (
              <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white" />
            ) : (
              'Create Account'
            )}
          </button>
        </div>
      </form>
      <ToLogin nav_to={props.nav_to} />
    </Container>
  );
}
