import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { removeSessionKey } from 'thirdweb/extensions/erc4337';
import { remove } from 'thirdweb/extensions/farcaster/keyRegistry';
import { removeSessionCookie } from '../../lib/session';
import { LoginStates } from '../../lib/types/LoginStates';

export default function ToLogin(props: {
  nav_to?: Dispatch<SetStateAction<LoginStates>>;
}) {
  const handleBackToLogin = () => {
    removeSessionCookie();
    if (props.nav_to) {
      props.nav_to(LoginStates.LOGGED_OUT);
    }
  };
  return (
    <div
      style={{
        marginTop: 20,
        display: 'flex',
        width: '100%',
      }}
    >
      <button
        onClick={() => handleBackToLogin()}
        type="submit"
        className="w-full bg-transparent hover:transparent text-grey-100 font-oswald font-bold py-2 px-4 focus:ring-offset-2 flex items-center justify-center"
      >
        Back To Login
      </button>
    </div>
  );
}
