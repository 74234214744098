import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import GoToCreate from './GoToCreate';
import { Container } from './Container';
import loginUser from '../../lib/mdc/login';
import { getSessionCookie } from '../../lib/session';
import { LoginStates } from '../../lib/types/LoginStates';
import UserSession from '../../lib/types/UserSession';

export default function LoginForm(props: {
  handleSetUser: (u: UserSession) => void;
  nav_to?: Dispatch<SetStateAction<LoginStates>>;
}) {
  const [message, setMessage] = useState<string>('');

  const [loading, setLoading] = useState(false);

  const handleNavToLoginIfSessionExists = (user?: UserSession) => {
    if (getSessionCookie()) {
      if (user) {
        props.handleSetUser(user);
      }
      props.nav_to?.(LoginStates.LOGGED_IN);
      return;
    }

    throw new Error('No session token received');
  };

  const handleRefreshSession = async () => {
    // grab the form information
    const username = (document.getElementById('username') as HTMLInputElement)
      .value;
    const password = (document.getElementById('password') as HTMLInputElement)
      .value;

    const data = await loginUser(username, password);

    handleNavToLoginIfSessionExists(data);
  };

  const handleLogin = (e: { preventDefault: () => void }) => {
    const run = async () => {
      try {
        await handleRefreshSession();
      } catch (err) {
        console.error(err);

        //@ts-ignore
        if (err.message === 'No session token received') {
          setMessage('Invalid username or password');
        } else {
          // @ts-ignore
          setMessage(err ?? 'An error occurred');
        }
      } finally {
        setLoading(false);
      }
    };
    e.preventDefault();
    setLoading(true);
    run();
  };

  const ErrorMessageDiv = useCallback(
    () => <p className="text-red-500 text-center">{message}</p>,
    [message],
  );

  return (
    <Container>
      <h1 className="text-3xl font-oswald font-bold mb-2 text-center">
        SIGN IN TO REDEEM 22
      </h1>
      <p className="text-center text-gray-600 mb-6">
        Welcome back! Make sure to check out the new drops.
      </p>
      <div>
        <ErrorMessageDiv />
      </div>
      <form className="space-y-4" onSubmit={handleLogin}>
        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Username
          </label>
          <input
            id="username"
            //   type="user"
            placeholder="Enter your username"
            className="w-full px-3 py-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500"
          />
        </div>
        <div>
          <label
            htmlFor="password"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Password
          </label>
          <input
            id="password"
            type="password"
            placeholder="Enter your password"
            className="w-full px-3 py-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500"
          />
        </div>
        <div className="mt-8">
          <button
            type="submit"
            className="w-full bg-green-500 hover:bg-green-600 text-white font-oswald font-bold py-2 px-4 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 flex items-center justify-center"
          >
            {loading ? (
              <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white" />
            ) : (
              'Login'
            )}
          </button>
        </div>
      </form>
      <GoToCreate nav_to={props.nav_to} />
    </Container>
  );
}
